import React, {ReactElement} from 'react';
import Wait from './Wait';
import {useAsync} from 'react-async';

import {User, AuthenticatedUser, ANONYMOUS, useCurrentUser} from './user';

import {ensureArray} from './util';

export type Duration = number;  // in milliseconds

export const formatDuration = (duration: Duration): string => {
  duration = Math.floor(duration / 1000);
  const seconds = String(duration % 60);
  duration = Math.floor(duration / 60);
  const minutes = String(duration % 60);
  const hours = Math.floor(duration / 60);
  return `${
    hours > 0
      ? `${hours}:`
      : ''
  }${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
};

export interface Recording {
  readonly id: string;
  readonly title: string;
  readonly course?: Course;
  readonly date: Date;
  readonly duration: Duration;
  readonly presenters: string[];
  readonly link: URL;
  readonly preview?: string;
}

export interface Course {
  readonly id: string;
  readonly title: string;
  readonly description?: string;
  readonly location?: string;
}

export interface PaginationOptions {
  readonly limit: number,
  readonly page: number,
}

export enum SortDirection {
  ASC = '',
  DESC = '_DESC',
}

export interface SortOptions {
  readonly field: 'DATE_CREATED' |
    'DATE_PUBLISHED' |
    'TITLE' |
    'SERIES_ID' |
    'MEDIA_PACKAGE_ID' |
    'CREATOR' |
    'CONTRIBUTOR' |
    'LANGUAGE' |
    'LICENSE' |
    'SUBJECT' |
    'DESCRIPTION' |
    'PUBLISHER' |
    'START';
  readonly direction: SortDirection;
}

export type SearchOptions = {sort?: SortOptions} & Partial<PaginationOptions>;

export type Page<T> = T & {total: number};

export class Opencast {
  readonly apiRoot: URL;
  readonly playerLink: (id: string) => URL;

  constructor({
    apiRoot = new URL(window.location.origin),
    playerLink = id => new URL(`/paella/ui/embed.html?id=${id}`, apiRoot),
  }: {apiRoot: URL, playerLink: (id: string) => URL}) {
    this.apiRoot = apiRoot;
    this.playerLink = playerLink;
  }

  private request = async (
    endpoint: string,
    options: RequestInit = {}
  ): Promise<Response> => {
    const response = await fetch(
      `${this.apiRoot.href}${endpoint}`,
      {
        ...options,
        credentials: 'include',
      },
    );
    if (!response.ok) throw response;
    return response;
  }

  logIn = async (username: string, password: string) => {
    const userData = new URLSearchParams();
    userData.append('j_username', username);
    userData.append('j_password', password);
    userData.append('_spring_security_remember_me', 'on');
    const response = await this.request('j_spring_security_check', {
      method: 'POST',
      body: userData,
    });
    return !(new URL(response.url).searchParams.has('error'));
  }

  logOut = async (): Promise<void> => {
    await this.request('j_spring_security_logout');
  }

  currentUser = async (): Promise<User> => {
    const result = await this.request('info/me.json');
    const userInfo = await result.json();
    if (userInfo.roles.includes('ROLE_USER')) {
      return new AuthenticatedUser(userInfo.user.username, userInfo.roles);
    } else {
      return ANONYMOUS;
    }
  }

  private search = async (
    entity: 'episode' | 'series',
    {
      id,
      query,
      series,
      limit,
      page,
      sort = {field: 'TITLE', direction: SortDirection.ASC},
    }: {
      query?: string,
      id?: string,
      series?: string,
    } & SearchOptions = {},
  ): Promise<Page<{results: any}>> => {
    const searchParams = String(new URLSearchParams({
      ...(query && {q: query.trim()}),
      ...(id && {id}),
      ...(series && {sid: series}),
      ...(limit && {limit: String(limit)}),
      ...(page && limit && {offset: page * limit}),
      sort: `${sort.field}${sort.direction}`,
    } as Record<string, string>));
    const response = await this.request(
      `search/${entity}.json${searchParams && `?${searchParams}`}`,
    );
    const {'search-results': {
      result: results,
      total,
    }} = await response.json();
    return {results, total};
  }

  private parseEpisode = ({id, mediapackage: {
    title,
    series,
    seriestitle,
    start,
    duration,
    creators,
    attachments,
  }}: Record<string, any>): Recording => ({
    id,
    title,
    course: series && {id: series, title: seriestitle},
    date: new Date(start),
    duration: parseInt(duration),
    presenters: creators ? ensureArray(creators.creator) : [],
    link: this.playerLink(id),
    preview: attachments && (() => {
      const preview = ensureArray(attachments.attachment)
        .find(({type}) => (type as string).endsWith('/player+preview'));
      return preview && preview.url;
    })(),
  })

  episodes = async (
    args: {
      query?: string,
      series?: string,
    } & SearchOptions = {},
  ): Promise<Page<{recordings: Recording[]}>> => {
    const {results, total} = await this.search('episode', args);
    return {
      recordings: ensureArray(results).map(this.parseEpisode),
      total,
    }
  }

  episode = async (id: string): Promise<Recording | undefined> => {
    const episode = await this.search('episode', {id});
    return episode && this.parseEpisode(episode.results);
  };

  private parseSeries = ({
    id,
    dcTitle: title,
    dcDescription: description
  }: Record<string, any>): Course => ({id, title, description});

  series = async (
    args: {query?: string} & SearchOptions
  ): Promise<Page<{courses: Course[]}>> => {
    const {results, total} = await this.search('series', args);
    return {
      courses: ensureArray(results).map(this.parseSeries),
      total,
    };
  }

  singleSeries = async (id: string): Promise<Course | undefined> => {
    const series = await this.search('series', {id});
    return series && this.parseSeries(series.results);
  };
}

const Context = React.createContext<Opencast | undefined>(undefined);

export const useOpencast = () => React.useContext(Context)!;

const configureOpencast: Promise<Opencast> =
  fetch('/config.json')
    .then(response => {
      if (response.ok) return response.json();
      if (response.status === 404) return {} as any;
      throw new Error('invalid configuration');
    })
    .then(({apiRoot, playerLink}) => new Opencast({
      ...(apiRoot && {apiRoot: new URL(apiRoot)}),
      // eslint-disable-next-line no-new-func
      ...(playerLink && {playerLink: new Function(
        'id',
        `return new URL(\`${playerLink}\`, '${apiRoot}');`
      ) as any}),
    }));

export const Component: React.FC = ({children}) => {
  return <Wait state={useAsync({
    promise: configureOpencast as Promise<Opencast | undefined>,
  })}>{
    opencast => <Context.Provider value={opencast}>
      {children}
    </Context.Provider>
  }</Wait>
};

export const Search: <T>(props: {
  fetch: (opencast: Opencast) => Promise<T | undefined>,
  children: (result: T) => ReactElement | null,
}) => ReactElement | null = ({fetch, children}) => {
  const state = useAsync(
    React.useCallback(
      ({opencast}: {opencast: Opencast}) => fetch(opencast),
      [fetch]
    ),
    {
      opencast: useOpencast(),
      watch: useCurrentUser().currentUser,
    },
  );
  return <Wait state={state}>{children}</Wait>
};
