import React from 'react';
import {Trans} from 'react-i18next';

import {formatDuration, Recording, Search} from './opencast';

import sitemap from './sitemap';

import {List} from './list';
import {Pagination, usePagination} from './pagination';

import styles from './recordings.module.css';

// Returns a component that renders one recording.
const createItem = (showCourse: boolean): React.FC<{item: Recording}> => (
  ({item}) => (
    <div className={styles.item}>
        <sitemap.recording.Link id={item.id} className={styles.thumbnail}>
          <span className={styles.duration}>{formatDuration(item.duration)}</span>
          <span className={styles.alignHelper} />
          <img src={item.preview} alt="thumbnail"/>
        </sitemap.recording.Link>
      <div className={styles.itemInfo}>
        <h3>
          <sitemap.recording.Link id={item.id}>{item.title}</sitemap.recording.Link>
        </h3>
        {
          new Intl.DateTimeFormat('default', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          }).format(item.date)
        }<br />
        {showCourse && item.course && <sitemap.course.Link id={item.course.id}>
          {item.course.title}
        </sitemap.course.Link>}
      </div>
    </div>
  )
);

// Component to render a list of recordings.
export const RecordingList: React.FC<{
  items: Recording[],
  pagination?: Pagination,
  showCourse?: boolean,
}> = ({items, pagination, showCourse = true}) => (
  <List items={items} pagination={pagination} component={createItem(showCourse)} />
);

// The full `/recordings` page body
const Recordings: React.FC = () => {
  const pagination = usePagination();
  return <Search fetch={
    React.useCallback(opencast => opencast.episodes(pagination), [pagination])
  }>{
    ({recordings, total}) => <>
      <h1><Trans count={total}>
        Recordings ({{count: total}})
      </Trans></h1>
      <RecordingList
        items={recordings}
        pagination={{total, ...pagination}}
      />
    </>
  }</Search>;
};

export default Recordings;
