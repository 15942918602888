import React from 'react';
import {Trans} from 'react-i18next';

import {Course, Search} from './opencast';

import sitemap from './sitemap';

import {List} from './list';
import {Pagination, usePagination} from './pagination';
import styles from './courses.module.css';


const Item: React.FC<{item: Course}> = ({item}) => (
  <sitemap.course.Link id={item.id} className={styles.item}>
    <h3>{item.title}</h3>
    {item.description && <p>{item.description}</p>}
  </sitemap.course.Link>
);

export const CourseList: React.FC<{
  items: Course[],
  pagination: Pagination,
}> = ({items, pagination}) => (
  <List items={items} pagination={pagination} component={Item} />
);

const Courses: React.FC = () => {
  const pagination = usePagination();
  return <Search fetch={
    React.useCallback(opencast => opencast.series(pagination), [pagination])
  }>{
    ({courses, total}) => <>
      <h1><Trans count={total}>
        Courses ({{count: total}})
      </Trans></h1>
      <CourseList
        items={courses}
        pagination={{total, ...pagination}}
      />
    </>
  }</Search>;
};

export default Courses;
