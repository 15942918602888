import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {Location} from 'history';

import * as opencast from './opencast';
import {RecordingList} from './recordings';
import {CourseList} from './courses';

import sitemap from './sitemap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import styles from './search.module.css';
import {usePagination} from './pagination';

const getQuery = (location: Location) =>
  new URLSearchParams(location.search).get('query') || undefined;

export const SearchBar = withRouter(({history, location}) => {
  const {t} = useTranslation();

  const [query, setQuery] = React.useState(getQuery(location) || '');
  let input = React.useRef<HTMLInputElement>(null);
  let button = React.useRef<HTMLButtonElement>(null);

  const search = (event: React.FormEvent<HTMLFormElement>) => {
    if (query === "") {
      return;
    }

    event.preventDefault();
    input.current?.blur();
    button.current?.blur();
    history.push(sitemap.search.routeTo({query}));
  };

  const blurOnEscape = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape" || event.key === "Esc") {
      input.current?.blur();
    }
  };

  return <form onSubmit={search} className={styles.searchBar}>
    <input
      value={query}
      id={styles.searchInput}
      ref={input}
      placeholder={t("Search")}
      onChange={event => setQuery(event.target.value)}
      onKeyDown={blurOnEscape}
    />
    <button ref={button} className={styles.button}>
      <FontAwesomeIcon icon={faSearch} />
    </button>
  </form>
});

export const SearchResults: React.FC<{query?: string}> = ({query}) => {
  const episodePagination = usePagination();
  const seriesPagination = usePagination(5);
  return <opencast.Search fetch={
    React.useCallback(opencast => Promise.all([
      opencast.episodes({query, ...episodePagination}),
      opencast.series({query, ...seriesPagination}),
    ]), [query, episodePagination, seriesPagination])}>{([
      {recordings, total: totalRecordings},
      {courses, total: totalCourses},
    ]) => <>
      <h1><Trans values={{query}}>Search results for "{{query}}"</Trans></h1>
      <h2><Trans count={totalCourses}>
        Courses ({{count: totalCourses}})
      </Trans></h2>
      <CourseList
        items={courses}
        pagination={{total: totalCourses, ...seriesPagination}}
      />
      <h2><Trans count={totalRecordings}>
        Recordings ({{count: totalRecordings}})
      </Trans></h2>
      <RecordingList
        items={recordings}
        pagination={{total: totalRecordings, ...episodePagination}}
      />
    </>}
  </opencast.Search>;
};
