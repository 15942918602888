import React, {useCallback} from 'react';
import {Trans} from 'react-i18next';
import {Recording as Model, Search} from './opencast';

import styles from './Recording.module.css';

const Player: React.FC<Model> = ({
  title,
  course,
  presenters,
  link,
}) => <>
  <div className={styles.playerWrap}>
    <div className={styles.heightDummy} />
    <iframe
      className={styles.player}
      title={title}
      src={link.href}
      allowFullScreen={true} />
  </div>
  <h1>{title}</h1>
  <h2>{course && course.title}</h2>

  <h3><em><Trans>Presenters</Trans>:</em> {presenters.join(', ')}</h3>
</>;

const Recording: React.FC<{id: string}> = ({id}) =>
  <Search fetch={
    useCallback(opencast => opencast.episode(id), [id])
  }>{Player}</Search>;

export default Recording;
